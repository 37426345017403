import { IMenuData } from "../../types";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import {
  mdiAccountGroup,
  mdiShieldAccount,
  mdiHomeGroupPlus,
  mdiHomeMapMarker,
  mdiTimelineClock,
  mdiMap,
  mdiTableClock,
  mdiCarMultiple,
  mdiHome,
  mdiOrderBoolDescending,
  mdiFileDocumentMultipleOutline,
  mdiSecurity,
  mdiCallSplit,
  mdiTarget,
  mdiChartBoxOutline,
  mdiMessageBadgeOutline,
} from "@mdi/js";

export const adminMenuData: IMenuData = {
  title: "titles.admin",
  icon: mdiShieldAccount,
  navLinks: [
    {
      text: "titles.dashboardHome",
      href: ROUTE_CONSTANTS.DASHBOARD.HOME.RELATIVE,
      icon: mdiHome,
    },
    {
      text: "titles.riders",
      href: ROUTE_CONSTANTS.DASHBOARD.RIDERS.ROOT.ABSOLUTE,
      icon: mdiAccountGroup,
    },
    {
      text: "titles.maintenanceDrivers",
      href: ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE_DRIVERS.ROOT.ABSOLUTE,
      icon: mdiAccountGroup,
    },
    {
      text: "titles.groups",
      href: ROUTE_CONSTANTS.DASHBOARD.GROUPS.ROOT.ABSOLUTE,
      icon: mdiHomeGroupPlus,
    },
    {
      text: "titles.zones",
      href: ROUTE_CONSTANTS.DASHBOARD.ZONES.ROOT.ABSOLUTE,
      icon: mdiHomeMapMarker,
    },
    {
      text: "titles.vehicles",
      href: ROUTE_CONSTANTS.DASHBOARD.VEHICLES.ROOT.ABSOLUTE,
      icon: mdiCarMultiple,
    },
    {
      text: "titles.attendanceTimeline",
      href: ROUTE_CONSTANTS.DASHBOARD.ATTENDANCE_TIMELINE.ROOT.ABSOLUTE,
      icon: mdiTimelineClock,
    },
    {
      text: "titles.attendanceReport",
      href: ROUTE_CONSTANTS.DASHBOARD.ATTENDANCE_REPORT.ROOT.ABSOLUTE,
      icon: mdiTableClock,
    },
    {
      text: "titles.pagingOrders",
      href: ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS.ROOT.ABSOLUTE,
      icon: mdiOrderBoolDescending,
    },
    {
      text: "titles.pagingHistory",
      href: ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS_HISTORY.ABSOLUTE,
      icon: mdiFileDocumentMultipleOutline,
    },
    {
      text: "titles.targetGroups",
      href: ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.ROOT.ABSOLUTE,
      icon: mdiTarget,
    },
    {
      text: "titles.liveMap",
      href: ROUTE_CONSTANTS.DASHBOARD.LIVE_MAP.ROOT.ABSOLUTE,
      icon: mdiMap,
    },
    {
      text: "titles.userManagement",
      href: ROUTE_CONSTANTS.DASHBOARD.USER_MANAGEMENT.ABSOLUTE,
      icon: mdiSecurity,
    },
    {
      text: "titles.thirdParties",
      href: ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.ROOT.ABSOLUTE,
      icon: mdiCallSplit,
    },
    {
      text: "titles.riderOrdersReport",
      href: ROUTE_CONSTANTS.DASHBOARD.RIDER_ORDERS_REPORT.ROOT.ABSOLUTE,
      icon: mdiChartBoxOutline,
    },
    {
      text: "titles.sendNotifcations",
      href: ROUTE_CONSTANTS.DASHBOARD.MESSAGES.ROOT.ABSOLUTE,
      icon: mdiMessageBadgeOutline,
    },
  ],
};
