import React, { useEffect, useState, useRef, FormEvent, memo } from "react";
import {
  Box,
  Text,
  Td,
  IconButton,
  Button,
  Select,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import Icon from "@mdi/react";
import {
  mdiEye,
  mdiMagnify,
  mdiPencil,
  mdiPlus,
  mdiTrayArrowDown,
} from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import {
  MaintenanceDriverListItem,
  IWorkingStatus,
  MaintenanceDriverSorts,
} from "src/api/types/maintenanceDrivers";
import { panelRidersService } from "src/api/services/riders";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import dateHelper from "src/utils/date-helper";
import { exportFile } from "src/utils/export-file";
import { API_ROUTES } from "src/constants/api-routes";
import { ApiConfig } from "src/api/config";
import { Pagination } from "src/components/app/Pagination";
import { DEFAULT_COUNT } from "src/constants/api-default-values";
import useQueryParam from "src/hooks/useSearchQuery";
import { workingStatusService } from "src/api/services/working-status";
import "./styles.css";
import { useAsync } from "src/hooks/useAsync";
import Table from "src/components/app/Table";
import { useTable } from "src/hooks/useTable";
import { useAbortController } from "src/hooks/useAbortController";
import { modalActions } from "src/global-context/modals";
import { MODAL_TYPES } from "../../../types/modals";
import { ICustomOption } from "src/types/table";
import { IFetchDataResponse } from "src/types/general";
import { maintenanceDriversService } from "../../../api/services/maintenance-drivers";

interface IFilters {
  query: string;
  status: string;
}

interface IFetchRidersFilters {
  offset: number;
  size: number;
  query?: string;
  status?: string;
}

const columnsTable = [
  {
    title: "Employee ID",
    dataIndex: "employeeId",
    isSortable: true,
    isHidden: false,
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    isSortable: true,
    isHidden: false,
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    isSortable: true,
    isHidden: false,
  },
  {
    title: "Zone",
    dataIndex: "zone",
    isSortable: false,
    isHidden: false,
  },
  {
    title: "Created at",
    dataIndex: "registerDate",
    isSortable: true,
    isHidden: false,
  },
];

type TCustomOptions = {
  zone: ICustomOption<{ nameEn: string }>;
  registerDate: ICustomOption;
  actions: ICustomOption<{ id: string }>;
};

const customOption: TCustomOptions = {
  zone: {
    title: "Zone",
    dataIndex: "zone",
    render: (value) => <Box>{value?.nameEn || "---"}</Box>,
  },
  registerDate: {
    title: "CREATED AT",
    dataIndex: "registerDate",
    render: (value) => <Box>{dateHelper.formatDate(value)}</Box>,
  },
  actions: {
    title: "",
    dataIndex: "actions",
    render: (_, item) => (
      <Box display="flex">
        <IconButton
          aria-label="Edit"
          icon={<Icon path={mdiPencil} size="28px" />}
          color="gray.100"
          bg="blue.300"
          as={Link}
          to={
            ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE_DRIVERS.UPDATE.BY_DATA(
              item?.id
            ).ABSOLUTE
          }
        />
        <IconButton
          aria-label="View"
          icon={<Icon path={mdiEye} size="28px" />}
          color="gray.100"
          bg="blue.300"
          as={Link}
          ml="2"
          to={
            ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE_DRIVERS.DETAILS.BY_DATA(
              item?.id
            ).ABSOLUTE
          }
        />
      </Box>
    ),
  },
};

const MaintenanceDriversList = memo(() => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.maintenanceDrivers"));

  const { getAbortSignal } = useAbortController();

  const exportButton = useRef<HTMLButtonElement>(null);
  const [filters, setFilters] = useState<IFilters>({
    query: "",
    status: "",
  });

  const { queryParams, updateQueryParams, initialized } = useQueryParam({
    initialFields: {
      size: 10,
      offset: 0,
      query: "",
      status: "",
    },
  });

  const {
    run: fetchRiders,
    isLoading,
    data: ridersData,
  } = useAsync<
    IFetchDataResponse<MaintenanceDriverListItem>,
    IFetchRidersFilters
  >(
    (options) => {
      const { offset, size, query = queryParams?.query, status = "" } = options;
      return new Promise(async (resolve, reject) => {
        try {
          const response = await maintenanceDriversService.getAll(
            offset,
            size,
            MaintenanceDriverSorts.ByRegisterDateDesc,
            getAbortSignal("fetchRiders").signal,
            query,
            status
          );
          resolve(
            response.data.data as IFetchDataResponse<MaintenanceDriverListItem>
          );
        } catch (e) {
          reject([]);
        }
      });
    },
    {
      defaultValue: undefined,
    }
  );

  const { run: fetchWorkingStatus, data: workingStatus } = useAsync<
    IWorkingStatus[],
    unknown
  >(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await workingStatusService.getAll();
        resolve(response.data.data.items as IWorkingStatus[]);
      } catch (e) {
        reject([]);
      }
    });
  });

  useEffect(() => {
    if (initialized) {
      const { offset, size, query, status } = queryParams;
      fetchRiders({ offset, size, query, status });
      fetchWorkingStatus({});
      setFilters({ query, status });
    }
  }, [initialized]);

  const { columnsArray } = useTable<any>({
    columnsTable: columnsTable,
    loading: isLoading,
    customSort: ["employeeId", "firstName", "lastName"],
    suffixColNames: ["actions"],
    customOption: customOption,
  });

  const exportCSV = () => {
    exportFile(
      ApiConfig.baseURL + API_ROUTES.RIDERS.EXPORT,
      {
        count: "" + DEFAULT_COUNT,
        keyword: "" + queryParams.query,
        // WorkingStatusId: "" + queryParams.status,
      },
      exportButton,
      `Riders-${dateHelper.getToday()}`
    );
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateQueryParams({ offset: offset, size: size });
    fetchRiders({ offset, size });
  };

  const handleChange = (name: string, value: string) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    let searchQuery: string = filters?.query?.toLocaleLowerCase().trim() || "";
    const searchStatus = filters?.status ? filters?.status : "";
    updateQueryParams({
      query: searchQuery,
      status: searchStatus,
      offset: 0,
    });
    fetchRiders({
      offset: 0,
      size: queryParams.size,
      query: searchQuery,
      status: searchStatus,
    });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.maintenanceDrivers")}
        </Text>
        <IconButton
          aria-label="New Maintenance Driver"
          as={Link}
          to={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE_DRIVERS.CREATE.ABSOLUTE}
          ml="4"
          icon={<Icon path={mdiPlus} size="28px" />}
          colorScheme="primary"
          variant="outline"
          size="sm"
        />
      </Box>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          mb="4"
          mt="4"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          as="form"
          noValidate
          onSubmit={handleSearch}
        >
          <Box className="" ml={2}>
            <label> Search </label>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray"
                mt="6px"
                children={<Icon path={mdiMagnify} size="24px" />}
              />
              <Input
                type="search"
                placeholder={t("actions.search")}
                backgroundColor="white"
                value={filters?.query}
                onChange={(e) => handleChange("query", e.target.value)}
                size="lg"
              />
            </InputGroup>
          </Box>
          <Box mt={6}>
            <Button size="lg" ml="3" colorScheme="primary" type="submit">
              Search
            </Button>
          </Box>
        </Box>
        <Box>
          {/*<Button*/}
          {/*  colorScheme="blackAlpha"*/}
          {/*  ml="2"*/}
          {/*  pr="20px"*/}
          {/*  pl="20px"*/}
          {/*  onClick={() =>*/}
          {/*    modalActions.addModal(MODAL_TYPES.BULK_UPLOAD_MODAL, {*/}
          {/*      bulkUploadApiUrl: API_ROUTES.RIDERS.BULK_INSERT,*/}
          {/*      title: "Rider",*/}
          {/*      maxValidSize: "200-KB",*/}
          {/*    })*/}
          {/*  }*/}
          {/*>*/}
          {/*  Bulk Upload*/}
          {/*</Button>*/}
          <Button
            bg="#F39905"
            color="#333"
            ml="2"
            pr="20px"
            pl="20px"
            ref={exportButton}
            onClick={() => exportCSV()}
          >
            <Icon path={mdiTrayArrowDown} size="25px" color="#333" />
            &nbsp;Export
          </Button>
        </Box>
      </Box>
      <Table
        loading={isLoading}
        dataSource={ridersData?.items}
        columns={columnsArray || []}
      />
      <Pagination
        entityTitle="Rider"
        loading={isLoading}
        pageTotalCount={ridersData?.totalCount || 0}
        perPageItems={queryParams.size || 10}
        passPaginateValues={handlePassPaginateValues}
      />
    </Box>
  );
});

export default MaintenanceDriversList;
