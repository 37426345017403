import {
  Box,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { panelRidersService } from "src/api/services/riders";
import { IRiderListItem } from "src/api/types/riders";
import { toast } from "src/utils/toast";
import { MaintenanceDriverListItem } from "../../../../../../api/types/maintenanceDrivers";
import { maintenanceDriversService } from "../../../../../../api/services/maintenance-drivers";

interface IRiderSwitchConfirmationModalProps {
  riderDetail: MaintenanceDriverListItem;
  openSwitchConfirmationModal: boolean;
  setOpenSwitchConfirmationModal: React.Dispatch<SetStateAction<boolean>>;
  handleConfirmChangeState: (newState: boolean) => void;
}

const SwitchConfirmationModal: React.FC<IRiderSwitchConfirmationModalProps> = (
  props
) => {
  const {
    riderDetail,
    openSwitchConfirmationModal,
    setOpenSwitchConfirmationModal,
    handleConfirmChangeState,
  } = props;
  const { isActive, id } = riderDetail;

  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [changeStateAbortController] = useState<AbortController>(
    new AbortController()
  );

  const handleRiderChangeState = async () => {
    // TODO: Check if rider is busy or checkin we must show error message to admin and prevent changing his/her status
    setLoading(true);
    try {
      if (isActive) {
        await maintenanceDriversService.toDisabledRider(
          id,
          changeStateAbortController.signal
        );
        toast.success(t("messages.riderWasDisabled"));
      } else {
        await maintenanceDriversService.toEnabledRider(
          id,
          changeStateAbortController.signal
        );
        toast.success(t("messages.riderWasEnabled"));
      }
      handleConfirmChangeState(!isActive);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpenSwitchConfirmationModal(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openSwitchConfirmationModal}
      onClose={() => setOpenSwitchConfirmationModal(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign="center"
          as="h3"
          fontSize="1.1rem"
          mb="-3"
          mt="3"
          fontWeight="500"
        >
          Are you sure that you want to {isActive ? "disable" : "enable"}{" "}
          driver?
        </ModalHeader>
        <ModalBody>
          <Box display="flex" mt="4" mb="4" gap="4">
            <Button
              flex="1"
              colorScheme="blackAlpha"
              ml="2"
              onClick={() => setOpenSwitchConfirmationModal(false)}
              isDisabled={loading}
            >
              No
            </Button>
            <Button
              flex="1"
              bg="#FEE4BA"
              color="#000"
              onClick={handleRiderChangeState}
              isLoading={loading}
            >
              Yes
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SwitchConfirmationModal;
