import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import { toast } from "src/utils/toast";
import { SelectImage } from "src/components/app/SelectImage";
import { ApiConfig } from "src/api/config";
import { API_ROUTES } from "src/constants/api-routes";
import { getAuthorization } from "src/utils/get-authorization";
import { BrowserBack } from "src/components/app/BrowserBack";
import { useAsync } from "../../../../hooks/useAsync";
import { useAbortController } from "../../../../hooks/useAbortController";
import FormGenerator from "src/components/FormGenerator";
import { useFormItems } from "src/hooks/useFormItems";
import { getCreateRiderFormItems } from "./form-item";
import { getMDriverValidation } from "./validation-schema";
import {
  IMaintenanceDriverData,
  MaintenanceDriverListItem,
} from "../../../../api/types/maintenanceDrivers";
import { maintenanceDriversService } from "../../../../api/services/maintenance-drivers";

const CreateMaintenanceDriver: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [editable, setEditable] = useState<boolean>(false);
  const [avatarFile, setAvatarFile] = useState<Blob>(new Blob());

  const params = useParams<{ id: string }>();
  useTitle(
    t(
      params.id
        ? "pageTitles.editMaintenanceDriver"
        : "pageTitles.newMaintenanceDriver"
    )
  );
  const { getAbortSignal } = useAbortController();

  const useFormReturn = useForm<IMaintenanceDriverData>({
    resolver: yupResolver(
      getMDriverValidation(t, params.id ? "update" : "create")
    ),
    mode: "all",
    defaultValues: {
      firstName: "",
      lastName: "",
      civilIdNo: "",
      rawPassword: "",
      contactNo: "",
      driverLicenseNo: "",
      employeeId: "",
      currentWorkingStatusId: 1,
      nextWorkingStatusId: 1,
    },
  });

  const {
    formState: { errors },
    reset,
    setError,
  } = useFormReturn;

  const { data: existingData } = useAsync<MaintenanceDriverListItem, unknown>(
    () => {
      return new Promise(async (resolve, reject) => {
        try {
          if (params.id) {
            const result = await maintenanceDriversService.get(
              params.id!,
              getAbortSignal("mDriverDetail").signal
            );

            const {
              avatar,
              id,
              currentWorkingStatus,
              nextWorkingStatus,
              ...rest
            } = result.data.data;
            resolve(result.data.data);
            reset({
              ...rest,
              ...(currentWorkingStatus?.id
                ? { currentWorkingStatusId: currentWorkingStatus.id }
                : {}),
              ...(nextWorkingStatus?.id
                ? { nextWorkingStatusId: nextWorkingStatus.id }
                : {}),
              // currentWorkingStatusId: currentWorkingStatus.id,
              // nextWorkingStatusId: nextWorkingStatus.id,
            });
          } else {
            reject();
          }
        } catch (e) {
          reject([]);
        }
      });
    },
    { immediate: true }
  );

  useEffect(() => {
    if (params.id) {
      setEditable(true);
    }
  }, [params]);

  const UploadRiderAvatar = (riderId: string, riderAvatarFile: Blob) => {
    const formData = new FormData();
    formData.append("File", riderAvatarFile);
    // if admin select avatar
    fetch(
      ApiConfig.baseURL + API_ROUTES.MAINTENANCE_DRIVERS.SET_AVATAR(riderId),
      {
        method: "PUT",
        headers: {
          Authorization: getAuthorization()!,
          accept: "*/*",
        },
        body: formData,
      }
    )
      .then((response) => {
        toast.success(
          t(
            editable
              ? "messages.mDriverUpdatedSuccessfully"
              : "messages.mDriverCreatedSuccessfully"
          )
        );
        editable
          ? navigate(-1)
          : navigate(
              ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE_DRIVERS.ROOT.ABSOLUTE
            );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = async (data: IMaintenanceDriverData) => {
    setLoading(true);
    try {
      if (editable) {
        /* Update existing Rider */
        data.nextWorkingStatusId = parseInt(data.nextWorkingStatusId as any);
        data.currentWorkingStatusId = parseInt(
          data.currentWorkingStatusId as any
        );
        // @ts-ignore
        delete data.rawPassword;
        await maintenanceDriversService.update(params.id!, data);

        if (avatarFile.size > 0) {
          UploadRiderAvatar(params.id!, avatarFile);
        } else {
          toast.success(
            t(
              editable
                ? "messages.mDriverUpdatedSuccessfully"
                : "messages.mDriverCreatedSuccessfully"
            )
          );
          navigate(-1);
        }
      } else {
        /* Create new Rider */
        // @ts-ignore
        delete data.nextWorkingStatusId;
        // @ts-ignore
        delete data.currentWorkingStatusId;
        const createResult = await maintenanceDriversService.create(data);

        if (avatarFile.size > 0) {
          UploadRiderAvatar("" + createResult.data.data, avatarFile);
        } else {
          toast.success(
            t(
              editable
                ? "messages.mDriverUpdatedSuccessfully"
                : "messages.mDriverCreatedSuccessfully"
            )
          );
          navigate(ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE_DRIVERS.ROOT.ABSOLUTE);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const checkFieldDuplication = useCallback(
    async (value: string, field: "Email" | "CivilIdNo" | "EmployeeId") => {
      try {
        const result =
          await maintenanceDriversService.checkUniqueFieldDublication(
            value,
            field
          );
        const errorField =
          field === "Email"
            ? "email"
            : field === "CivilIdNo"
            ? "civilIdNo"
            : "employeeId";
        if (result.data.data.items.length) {
          setError(errorField, { message: "Entered value already exists" });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [setError]
  );

  const onCivilIdBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!errors.civilIdNo) {
        if (existingData && existingData.civilIdNo !== e.target.value) {
          checkFieldDuplication(e.target.value, "CivilIdNo");
        }
      }
    },
    [checkFieldDuplication, errors.civilIdNo, existingData]
  );

  const onEmployeeIdBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!errors.employeeId) {
        if (existingData && existingData?.employeeId !== e.target.value) {
          checkFieldDuplication(e.target.value, "EmployeeId");
        }
      }
    },
    [checkFieldDuplication, errors.employeeId, existingData]
  );

  const createRiderFormItems = useMemo(
    () =>
      getCreateRiderFormItems({
        t,
        getAbortSignal,
        onEmployeeIdBlur,
        onCivilIdBlur,
        editable,
      }),
    [t, getAbortSignal, onEmployeeIdBlur, onCivilIdBlur, editable]
  );
  const { formItems, refreshFormItems } = useFormItems(createRiderFormItems);

  // as we have to use the updated values like existingData in the onEmployeeIdBlur and onCivilIdBlur
  // so we need to update thier values on the basis of existigData
  useEffect(() => {
    if (existingData) {
      refreshFormItems(createRiderFormItems);
    }
  }, [existingData]);

  return (
    <ContentView>
      <ContentViewHeader>
        <Box display="flex" alignItems="center">
          <Text as="h3" fontSize="3xl">
            {t(
              editable
                ? "titles.editMaintenanceDriver"
                : "titles.newMaintenanceDriver"
            )}
          </Text>
          <BrowserBack />
        </Box>
      </ContentViewHeader>

      <ContentViewBody>
        <FormGenerator
          before={
            <SelectImage
              avatarUrl={existingData?.avatar!}
              onChange={setAvatarFile}
            />
          }
          isCancel={true}
          submitText={t("actions.save")}
          cancelText={t("actions.cancel")}
          onCancel={() =>
            editable
              ? navigate(-1)
              : navigate(
                  ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE_DRIVERS.ROOT.ABSOLUTE
                )
          }
          submitLoading={loading}
          formItems={formItems}
          onSubmit={onSubmit}
          useFormReturn={useFormReturn as any}
        />
      </ContentViewBody>
    </ContentView>
  );
};

export default CreateMaintenanceDriver;
